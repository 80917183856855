
import {Component, Watch} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';

import {investmentsModule} from '@/modules/investments/shared/state/module';

import {
    landModule,
    LandDataRequest, Land,
} from '../shared';
import {
    investmentObjectsModule,
    investmentSubjectOperationsModule
} from '@/modules/investments/shared/state/submodules';
import {landAssignableItems} from '@/shared/config/lands';
import {httpClient, logger} from '@/shared/services';
import {apiVersion} from '@/environment/environment';
import {callSuper, objectToQueryString} from '@/shared/helpers';
import {AxiosResponse} from 'axios';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import Hint from '@/shared/components/elements/hint.vue';

@Component({
    components: {
        FormActions,
        ActionConfirmDialog,
        Wysiwyg,
        Hint,
    },
})
export default class LandForm extends FormComponent<LandDataRequest> {
    public actionsTypes = landModule.actionsTypes;
    public store: any = this.$store.state.landsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new LandDataRequest();
    public assignableSearch: any = null;
    public searchAssignable: string = '';

    public investmentsState = this.$store.state.investmentsState;
    public investmentsActions = investmentsModule.actionsTypes;
    public objectsActions = investmentObjectsModule.actionsTypes;
    public subjectsActions = investmentSubjectOperationsModule.actionsTypes;
    public landAssignableItems = landAssignableItems;

    public searchLand = '';
    public landSearch: any = null;
    public landActions = landModule.actionsTypes;
    public lands: Land[] = [];
    public landsLoading: boolean = false;
    public currentTab: number = 0;
    public landStatuses: any = [
        {value: 'acquired', name: 'Nabyty'},
        {value: 'considered', name: 'Rozważany'},
        {value: 'planned_for_purchase', name: 'Planowany do nabycia'},
        {value: 'for_development', name: 'Do zagospodarowania'},
        {value: 'developed', name: 'Zagospodarowany'},
        {value: 'for_sale', name: 'Do sprzedaży'},
    ];
    public investments: any = [];
    public assignableSubjects: any = [];
    public assignableObjects: any = [];
    public currentAssignableId: number = 0;

    public setNoPlan() {
        if (this.itemData.plan_purpose) {
            this.itemData.plan_purpose = null;
        }
        if (this.itemData.acceptable_building_height) {
            this.itemData.acceptable_building_height = null;
        }
        if (this.itemData.acceptable_building_percentage) {
            this.itemData.acceptable_building_percentage = null;
        }
    }

    public fetchLands(search?: string) {
        const params = search ? {filters: {search}} : {};

        let url = `/api/v${apiVersion}/lands`;

        const queryString = objectToQueryString(params);

        url += (params ? '?' + queryString.toString() : '');

        this.landsLoading = true;
        httpClient.get(url)
            .then(({data}: AxiosResponse<ModuleFetchPayload<Land>>) => {
                if (data && data.data) {
                    this.landsLoading = false;
                    this.lands = data.data.filter((land) => land.id !== this.itemData.id);
                }
            })
            .catch((err) => {
                this.landsLoading = false;
                logger.error(err);
            });
    }

    @Watch('itemData.id')
    public onIdChange() {
        this.currentAssignableId = this.itemData.assignable_id;
        this.fetchLands();
    }

    public searchLands(search: string) {
        clearTimeout(this.landSearch);

        this.landSearch = setTimeout(() => {
            if (!this._.isEqual(this.searchLand, search)) {
                this.searchLand = search;
                this.fetchLands(search);
            }
        }, 500);
    }

    public landChanged() {
        this.clearError('parent_id');
        if (this.itemData.parent_id) {
            this.itemData.parent = this.lands.find((e: Land) => e.id === this.itemData.parent_id);
            if (this.itemData.parent) {
                this.searchLand = this.itemData.parent.name;
            }
        } else {
            this.fetchLands();
        }
    }


    public created() {
        this.initFocus();
        if (!this.itemData.assignable) {
            this.fetchAssignables();
        }
    }

    public fetchAssignables(fetchAll: boolean = true) {
        if (!fetchAll) {
            switch (this.itemData.assignable_type) {
                case 'investment-subject':
                    this.$store.dispatch(this.subjectsActions.FETCH_DATA,
                                        {id: this.itemData.investment_id, simple: false})
                    .then(({data}) => {
                        this.assignableSubjects = data.filter((item: any) =>
                            item.id === this.currentAssignableId || item.land_id === null);
                    });
                    break;
                case 'investment-object':
                    this.$store.dispatch(this.objectsActions.FETCH_DATA,
                                        {id: this.itemData.investment_id, simple: false})
                    .then(({data}) => {
                        this.assignableObjects = data.filter((item: any) =>
                            item.id === this.currentAssignableId || item.land_id === null);
                    });
                    break;
            }
        } else {
            this.$store.dispatch(this.subjectsActions.FETCH_DATA, {simple: false});
            this.$store.dispatch(this.objectsActions.FETCH_DATA, {simple: false});
        }
    }

    public initForm() {
        this.form = this.$refs.createLandForm;
    }

    public mounted() {
        this.$store.dispatch(this.investmentsActions.FETCH_DATA, {simple: false}).then(({data}) => {
            this.investments = data;
        });
        this.currentAssignableId = this.itemData.assignable_id;
    }

    public assignableTypeChanged() {
        this.$delete(this.itemData, 'assignable_id');
        this.$delete(this.itemData, 'assignable');
        this.clearError('assignable_type');
        this.fetchAssignables(false);
    }



    get formTitle() {
        return this.edit ? 'Edytuj grunt' : 'Dodaj grunt';
    }

    get assignableType() {
        return this.landAssignableItems.find((e) => e.id === this.itemData.assignable_type);
    }

    get assignableLoading() {
        switch (this.itemData.assignable_type) {
            case 'investment-subject':
                return this.subjectsLoading;
            case 'investment-object':
                return this.objectsLoading;
            default:
                return false;
        }
    }

    get assignableItems() {
        switch (this.itemData.assignable_type) {
            case 'investment-subject':
                return this.assignableSubjects;
            case 'investment-object':
                return this.assignableObjects;
            default:
                return [];
        }
    }

    set assignableItems(value) {
        switch (this.itemData.assignable_type) {
            case 'investment-subject':
                this.assignableSubjects = value;
            case 'investment-object':
                this.assignableObjects = value;
        }
    }

    get assignableActions() {
        switch (this.itemData.assignable_type) {
            case 'investment-subject':
                return this.subjectsActions;
            case 'investment-object':
                return this.objectsActions;
            default:
                return {};
        }
    }

    public assignableChanged() {
        const assigned = this.assignableItems.find((e: any) => e.id === this.itemData.assignable_id);
        if (assigned) {
            this.searchAssignable = assigned.name;
        }
        this.clearError('assignable_id');
    }

    public searchAssignables(search: string) {
        clearTimeout(this.assignableSearch);

        this.assignableSearch = setTimeout(() => {
            if (!this._.isEqual(this.searchAssignable, search) && this.itemData.investment_id) {
                delete this.itemData.assignable;
                this.searchAssignable = search;

                this.$store.dispatch(this.assignableActions.FETCH_DATA,
                    {id: this.itemData.investment_id, filters: {search}})
                .then(({data}) => {
                    this.assignableItems = data.filter((item: any) =>
                        item.id === this.currentAssignableId || item.land_id === null);
                });
            }
        }, 500);
    }

    public initFocus() {
        if (this.edit && this.itemData.assignable) {
            this.searchAssignable = this.itemData.assignable.name;
        }
    }


    get subjects() {
        return this.investmentsState.subjectOperationsState.data;
    }

    get objects() {
        return this.investmentsState.objectsState.data;
    }

    get subjectsLoading() {
        return this.investmentsState.subjectOperationsState.loading;
    }

    get objectsLoading() {
        return this.investmentsState.objectsState.loading;
    }

}
