
    import { Component } from 'vue-property-decorator';
    import ItemsTable from '@/shared/components/layout/list.vue';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import { ModuleProps } from '@/shared/state/template/module-props';

    import LandDetails from './details.vue';
    import LandForm from './form.vue';
    import { landModule, landListHeaders, Land, LandDataRequest } from '../shared';

    // @ts-ignore-next-line
    import ILiteApi from '@/shared/helpers/imapLiteApi-core';
    import { ListFilter } from '@/shared/types';
    import { investmentsModule } from '@/modules/investments/shared/state/module';
    import { logger } from '@/shared/services';
    import { landsListFilters } from '@/modules/lands/shared/config/filters';
    import { landStatuses } from '@/shared/config/lands';

    @Component({
        components: {
            ItemsTable,
            LandDetails,
            LandForm,
        },
    })
    export default class LandsList extends ListComponent<Land, LandDataRequest> {
        public headers: object[] = landListHeaders;
        public actionsTypes = landModule.actionsTypes;
        public mutationTypes = landModule.mutationsTypes;
        public fetchAction: string = this.actionsTypes.FETCH_DATA;
        public removeAction: string = this.actionsTypes.REMOVE_ITEM;
        public investmentsActions = investmentsModule.actionsTypes;
        public mapDialog = false;
        public store: any = this.$store.state.landsState;
        public props: ModuleProps = landModule.moduleProps;
        public filters: ListFilter[] = landsListFilters;
        public landStatuses = landStatuses.filter((status) =>
            ['acquired', 'considered', 'planned_for_purchase', 'for_development', 'developed', 'for_sale'].includes(
                status.name,
            ),
        );

        get investmentsLoading() {
            return this.$store.state.investmentsState.loadingList;
        }

        get investments() {
            return this.$store.state.investmentsState.dataList;
        }

        public openMap() {
            this.mapDialog = true;

            setTimeout(() => {
                ILiteApi.init({
                    divId: 'iapi',
                    imapLiteUrl: 'https://mapy.geoportal.gov.pl/iMapLite/',
                    width: '100%',
                    height: '100vh',
                    activeGpMapId: 'gp4',
                });
            }, 100);
        }

        public mounted() {
            if (!this.filters.map(({ name }) => name).includes('investment_id')) {
                this.$store
                    .dispatch(this.investmentsActions.FETCH_DATA, { simple: true })
                    .then(() => {
                        this.filters = [
                            ...this.filters,
                            {
                                type: 'search',
                                name: 'investment_id',
                                label: 'Inwestycja',
                                data: this.investments,
                                description: 'Wybierz inwestycje',
                            },
                        ];
                    })
                    .catch((error) => {
                        logger.error(error);
                    });
            }
        }

        public statusesMethod(value: string) {
            return landStatuses.find((obj) => obj.value === value);
        }
    }
