import {ListFilter} from '@/shared/types';

export const landsListFilters: ListFilter[] = [
    {
        name: 'plot_area',
        type: 'range',
        label: 'Powierzchnia działki',
        description: 'Wybierz zakres powierzchni działki',
    },
];
