

/**
 *  Dependencies list:
 *  - shared
 *  - module
 *  - icons
 */
import {Component} from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';

// @ts-ignore-next-line
import ILiteApi from '@/shared/helpers/imapLiteApi-core';

import {
    landModule,
    Land
} from '../shared';

import {mdiEmail} from '@mdi/js';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';

@Component({
    props: {
        id: String,
    },
    components: {
        TopToolbar,
    },
})
export default class LandDetails extends DetailsComponent<Land> {
    public store: any = this.$store.state.landsState;
    public actionsTypes = landModule.actionsTypes;
    public showAction: string = this.actionsTypes.SHOW_ITEM;

    public created() {
        //
    }
}
