export const landAssignableItems = [
    {
        id: 'investment-subject',
        name: 'Przedmiot inwestycji',
    },
    {
        id: 'investment-object',
        name: 'Kategoria inwestycji',
    },
];

export const landStatuses = [
    {value: 'acquired', name: 'Nabyty', color: '#673AB7'},
    {value: 'considered', name: 'Rozważany', color: '#D84315'},
    {value: 'planned_for_purchase', name: 'Planowany do nabycia', color: '#0277BD'},
    {value: 'for_development', name: 'Do zagospodarowania', color: '#FB8C00'},
    {value: 'developed', name: 'Zagospodarowany', color: '#00897B'},
    {value: 'for_sale', name: 'Do sprzedaży', color: '#7986CB'},
];

